import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, ColumnsType, Link, Spin, Table, Tag } from '~/core-components'
import { usePayGroupsDict } from '~/features/master'
import { Screen, updateViewCriteria, useFirstView, ViewCriteria, ViewCriteriaSimple } from '~/features/selection'
import { useIsMountedRef } from '~/hooks'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { CpfSubmissionStatus } from '~/constants'
import { apiGetCpfSubmittedFiles } from '../../api/cpf-submission.api'
import { fetchCpfSubmissionsView } from '../../actions'
import { selectCpfSubmissionsView } from '../../selectors'
import { refetchCpfSubmissionsView } from '../../reducers'
import { CpfSubmissionRowState } from '../../types'
import { CpfSubmissionStatusTag } from './CpfSubmissionStatusTag'
import { CpfSubmissionDrawer } from './CpfSubmissionDrawer'
import './CpfSubmissions.less'

interface CpfSubmissionsProps {}

interface DrawerState {
  visible: boolean
  id?: string
}

const DRAWER_STATE: DrawerState = { visible: false }

const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'cpf_submission'
const PAGE_SIZE_OPTIONS = ['15', '30', '50']

export const CpfSubmissions: FC<CpfSubmissionsProps> = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.payroll.cpfSubmissionsViewLoading)
  const data = useSelector(selectCpfSubmissionsView)(viewId)
  const refetch = useSelector((state: StoreState) => state.payroll.cpfSubmissionsViewRefetch)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [drawerState, setDrawerState] = useState<DrawerState>(DRAWER_STATE)

  const [payGroupsDict] = usePayGroupsDict()

  useEffect(() => {
    if (viewId) {
      dispatch(fetchCpfSubmissionsView(viewId, { offset: pageSize * (page - 1), limit: pageSize }))
    }
  }, [viewId, page, pageSize, refetch])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchCpfSubmissionsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleDownload = useCallback(
    async (id: string) => {
      if (id) {
        try {
          setDownloading(true)

          const { status, result, errors, message, errorData } = await apiGetCpfSubmittedFiles(id)
          if (status) {
            const fileName = `cpf_file_${getFileTimestamp()}.zip`
            downloadWithDom(result, fileName)
          } else {
            console.error('Error while downloading', errors)
            showError(message, errorData)
          }
        } finally {
          if (isMountedRef.current) setDownloading(false)
        }
      }
    },
    [isMountedRef]
  )

  const handleOpenDrawer = useCallback(async (id: string) => {
    setDrawerState({ id, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DRAWER_STATE)
  }, [])

  const columns: ColumnsType<CpfSubmissionRowState> = useMemo(
    () => [
      {
        title: 'Payment month',
        key: 'paymentMonth',
        dataIndex: 'paymentMonth',
        className: 'first-col',
        width: 120
      },
      {
        title: 'CPF submission no. (CSN)',
        key: 'cpfNo',
        dataIndex: 'cpfNo',
        width: 180
      },
      {
        title: 'Advice code',
        key: 'adviceCode',
        dataIndex: 'adviceCode',
        width: 90
      },
      {
        title: 'Payroll groups',
        key: 'payGroupIds',
        dataIndex: 'payGroupIds',
        render: (values: string[]) =>
          values && values.length > 0
            ? values.map((id: string) => <Tag>{payGroupsDict[id]?.name}</Tag>)
            : 'All payroll groups'
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 90,
        render: (value: string) => <CpfSubmissionStatusTag status={value} />
      },
      {
        title: 'Submitted date',
        key: 'createdDate',
        dataIndex: 'createdDate',
        width: 160,
        render: (value: string) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '')
      },
      {
        key: 'action',
        dataIndex: 'id',
        align: 'right',
        render: (id: string, record) =>
          record.status === CpfSubmissionStatus.submitted ? (
            <Link size="small" onClick={() => handleDownload(id)}>
              {downloading ? <Spin indicator={<LoadingOutlined spin />} /> : 'download'}
            </Link>
          ) : (
            <Link size="small" onClick={() => handleOpenDrawer(id)}>
              details
            </Link>
          )
      }
    ],
    [downloading, handleDownload, handleOpenDrawer, payGroupsDict]
  )

  return (
    <Card className="cpf-submissions" title="CPF submissions" table>
      <div className="cpf-submissions__action-bar">
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
      </div>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        fitParent
        loading={dataLoading || viewLoading}
        pagination={{
          total: data?.count,
          current: page,
          pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          onChange: handlePaginationChange,
          style: paginationStyle
        }}
      />
      <CpfSubmissionDrawer {...drawerState} onClose={handleCloseDrawer} />
    </Card>
  )
}
