import { FC } from 'react'
import { Col, Row } from '~/components'
import { CpfSubmissions } from '../CpfSubmissions/CpfSubmissions'
import { CpfSubmissionCard } from '../CpfSubmissionForm/CpfSubmissionCard'

interface CpfOverviewProps {}

export const CpfOverview: FC<CpfOverviewProps> = () => {
  return (
    <Row gutter={24} style={{ margin: '0 4px 24px' }}>
      <Col lg={24} xl={8}>
        <CpfSubmissionCard />
      </Col>
      <Col lg={24} xl={16}>
        <CpfSubmissions />
      </Col>
    </Row>
  )
}
