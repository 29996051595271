import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { EmSelectState } from '~/components'
import { IdValue } from '~/types/common'
import { StoreState } from '~/types/store'
import {
  PayrollRootState,
  PayRecurringState,
  PayRecurringOverrideState,
  PayRunState,
  PayRunSummaryState,
  PayRecordSgState,
  PayAccountState,
  PayTranSgState,
  PayTranDtlSgState,
  PayTranEntryState,
  PayTranImportState,
  PayItemMappingState,
  PayrollState,
  PaySnapshotState,
  PayslipState,
  PayWarningState,
  PayImportState,
  PayImportValidateState,
  LeaveImportState,
  LeaveImportValidateState,
  EncashImportState,
  EncashImportValidateState,
  EmPayrollState,
  payrollPersistConfig,
  PayRunPayslipState,
  ClaimImportState,
  ClaimImportValidateState,
  DailyImportState,
  DailyImportValidateState,
  PayRunViewState,
  XeroConnectionState,
  XeroTenantState,
  XeroTenantMapState,
  XeroLineItemMapState,
  XeroAccountState,
  XeroContactState,
  PayRunXeroState,
  XeroConfigState,
  PayCfConfigState,
  CpfSubmissionViewState,
  CpfSubmissionFormState,
  CpfSubmissionState
} from './types'

const payRecurringAdapter = createEntityAdapter<PayRecurringState>()
const payRecurringOverrideAdapter = createEntityAdapter<PayRecurringOverrideState>()
const payRunAdapter = createEntityAdapter<PayRunState>()
const payRunSummaryAdapter = createEntityAdapter<PayRunSummaryState>()
const payRunViewAdapter = createEntityAdapter<PayRunViewState>({
  selectId: model => model.viewId
})
const payRecordAdapter = createEntityAdapter<PayRecordSgState>()
const payRecordPayRunAdapter = createEntityAdapter<IdValue>()
const payRecordEmployeeAdapter = createEntityAdapter<EmSelectState>()
const payTranAdapter = createEntityAdapter<PayTranSgState>()
const payTranDtlAdapter = createEntityAdapter<PayTranDtlSgState>()
const payTranEntryAdapter = createEntityAdapter<PayTranEntryState>()
const payTranImportAdapter = createEntityAdapter<PayTranImportState>()
const payAccountAdapter = createEntityAdapter<PayAccountState>()
const payItemMappingAdapter = createEntityAdapter<PayItemMappingState>({
  selectId: model => model.referenceCode
})
const payrollAdapter = createEntityAdapter<PayrollState>()
const payWarningAdapter = createEntityAdapter<PayWarningState>()
const paySnapshotAdapter = createEntityAdapter<PaySnapshotState>()
const emPayrollAdapter = createEntityAdapter<EmPayrollState>()
const payslipAdapter = createEntityAdapter<PayslipState>()
const payRunPayslipAdapter = createEntityAdapter<PayRunPayslipState>()
const payRunXeroAdapter = createEntityAdapter<PayRunXeroState>({
  selectId: model => model.payRunId
})
const payCfConfigsAdapter = createEntityAdapter<PayCfConfigState>({ selectId: model => model.code })

const cpfSubmissionsViewAdapter = createEntityAdapter<CpfSubmissionViewState>({
  selectId: model => model.viewId
})
const cpfSubmissionsAdapter = createEntityAdapter<CpfSubmissionState>()

const xeroConnectionsAdapter = createEntityAdapter<XeroConnectionState>()
const xeroTenantsAdapter = createEntityAdapter<XeroTenantState>()
const xeroTenantMapsAdapter = createEntityAdapter<XeroTenantMapState>()
const xeroConfigAdapter = createEntityAdapter<XeroConfigState>({
  selectId: model => model.companyId
})
const xeroLineItemMapsAdapter = createEntityAdapter<XeroLineItemMapState>()
const xeroAccountsAdapter = createEntityAdapter<XeroAccountState>()
const xeroContactsAdapter = createEntityAdapter<XeroContactState>()

const payRecurringInitialState = payRecurringAdapter.getInitialState()
const payRecurringOverrideInitialState = payRecurringOverrideAdapter.getInitialState()
const payRunInitialState = payRunAdapter.getInitialState()
const payRunSummaryInitialState = payRunSummaryAdapter.getInitialState()
const payRunViewInitialState = payRunViewAdapter.getInitialState()
const payRecordInitialState = payRecordAdapter.getInitialState()
const payRecordPayRunInitialState = payRecordPayRunAdapter.getInitialState()
const payRecordEmployeeInitialState = payRecordEmployeeAdapter.getInitialState()
const payTranInitialState = payTranAdapter.getInitialState()
const payTranDtlInitialState = payTranDtlAdapter.getInitialState()
const payTranEntryInitialState = payTranEntryAdapter.getInitialState()
const payTranImportInitialState = payTranImportAdapter.getInitialState()
const payAccountInitialState = payAccountAdapter.getInitialState()
const payItemMappingInitialState = payItemMappingAdapter.getInitialState()
const payrollInitialState = payrollAdapter.getInitialState()
const payWarningInitialState = payWarningAdapter.getInitialState()
const paySnapshotInitialState = paySnapshotAdapter.getInitialState()
const emPayrollInitialState = emPayrollAdapter.getInitialState()
const payslipInitialState = payslipAdapter.getInitialState()
const payRunPayslipInitialState = payRunPayslipAdapter.getInitialState()
const payRunXeroInitialState = payRunXeroAdapter.getInitialState()
const payCfConfigsInitialState = payCfConfigsAdapter.getInitialState()
const cpfSubmissionsViewInitialState = cpfSubmissionsViewAdapter.getInitialState()
const cpfSubmissionsInitialState = cpfSubmissionsAdapter.getInitialState()
const xeroConnectionInitialState = xeroConnectionsAdapter.getInitialState()
const xeroTenantInitialState = xeroTenantsAdapter.getInitialState()
const xeroTenantMapInitialState = xeroTenantMapsAdapter.getInitialState()
const xeroConfigInitialState = xeroConfigAdapter.getInitialState()
const xeroLineItemMapInitialState = xeroLineItemMapsAdapter.getInitialState()
const xeroAccountInitialState = xeroAccountsAdapter.getInitialState()
const xeroContactInitialState = xeroContactsAdapter.getInitialState()

const initialState: PayrollRootState = {
  payRecurrings: payRecurringInitialState,
  payRecurringsLoading: false,
  payRecurringOverrides: payRecurringOverrideInitialState,
  payRecurringOverridesLoading: false,
  payRunExpanded: false,
  payRuns: payRunInitialState,
  payRunsLoading: false,
  payRunSummaries: payRunSummaryInitialState,
  payRunSummariesLoading: false,
  payRunSummariesRefetch: 0,
  payRunsView: payRunViewInitialState,
  payRunsViewLoading: false,
  payRunsViewRefetch: 0,
  payRecords: {},
  payRecordsLoading: {},
  payRecordsRefetch: 0,
  payRecordPayRuns: {},
  payRecordPayRunsLoading: {},
  payRecordEmployees: {},
  payRecordEmployeesLoading: {},
  payWarnings: {},
  payWarningLoading: {},
  paySnapshot: {},
  paySnapshotLoading: {},
  payTrans: {},
  payTransLoading: {},
  payTranDtls: {},
  payTranDtlsLoading: {},
  payTranEntries: {},
  payTranEntriesLoading: {},
  payTranImports: {},
  payTranImportsLoading: {},
  payAccounts: {},
  payAccountsLoading: {},
  payItemMappings: payItemMappingInitialState,
  payItemMappingsLoading: false,
  payroll: payrollInitialState,
  payrollLoading: false,
  emPayroll: emPayrollInitialState,
  emPayrollLoading: false,
  payslip: payslipInitialState,
  payslipLoading: false,
  payslipGenerating: false,
  payslipEmployeeRefetch: 0,
  isPayslipOutdated: {},
  isAnyPayslipsOutdated: {},
  payImport: undefined,
  payImportValidate: undefined,
  payRunPayslip: {},
  payRunPayslipLoading: {},
  payRunXero: payRunXeroInitialState,
  payRunXeroLoading: false,
  payRunXeroRefecth: 0,
  payCfConfigs: payCfConfigsInitialState,
  payCfConfigsLoading: false,

  // Cpf submission
  cpfSubmissionsView: cpfSubmissionsViewInitialState,
  cpfSubmissionsViewLoading: false,
  cpfSubmissionsViewRefetch: 0,
  cpfApexState: '',
  cpfApexStateTimestamp: 0,
  cpfSubmissionForm: {
    paymentMonth: '',
    cpfNo: '',
    adviceCode: '',
    payGroupIds: [],
    cpfLateInterest: 0,
    paymentMode: '',
    deductionDate: ''
  },
  cpfSubmissions: cpfSubmissionsInitialState,
  cpfSubmissionsLoading: false,

  // Xero integration
  xeroState: '',
  xeroStateTimestamp: 0,
  xeroRedirectUrl: '',
  xeroConnections: xeroConnectionInitialState,
  xeroConnectionsLoading: false,
  xeroTenants: xeroTenantInitialState,
  xeroTenantsLoading: false,
  xeroTenantMaps: xeroTenantMapInitialState,
  xeroTenantMapsLoading: false,
  xeroConfig: xeroConfigInitialState,
  xeroConfigLoading: false,
  xeroLineItemMaps: {},
  xeroLineItemMapsLoading: {},
  xeroAccounts: {},
  xeroAccountsLoading: {},
  xeroContacts: {},
  xeroContactsLoading: {}
}

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setPayRecurring: (state, action: PayloadAction<PayRecurringState>) => {
      payRecurringAdapter.upsertOne(state.payRecurrings, action.payload)
    },
    setPayRecurringLoading: (state, action: PayloadAction<boolean>) => {
      state.payRecurringsLoading = action.payload
    },
    replacePayRecurrings: (state, action: PayloadAction<PayRecurringState[]>) => {
      payRecurringAdapter.setAll(state.payRecurrings, action.payload)
    },
    removePayRecurring: (state, action: PayloadAction<string>) => {
      payRecurringAdapter.removeOne(state.payRecurrings, action.payload)
    },
    setPayRecurringOverride: (state, action: PayloadAction<PayRecurringOverrideState>) => {
      payRecurringOverrideAdapter.upsertOne(state.payRecurringOverrides, action.payload)
    },
    setPayRecurringOverrideLoading: (state, action: PayloadAction<boolean>) => {
      state.payRecurringOverridesLoading = action.payload
    },
    removePayRecurringOverride: (state, action: PayloadAction<string>) => {
      payRecurringOverrideAdapter.removeOne(state.payRecurringOverrides, action.payload)
    },
    setPayRunExpanded: (state, action: PayloadAction<boolean>) => {
      state.payRunExpanded = action.payload
    },
    setPayRun: (state, action: PayloadAction<PayRunState>) => {
      payRunAdapter.upsertOne(state.payRuns, action.payload)
    },
    setPayRunLoading: (state, action: PayloadAction<boolean>) => {
      state.payRunsLoading = action.payload
    },
    setPayRuns: (state, action: PayloadAction<PayRunState[]>) => {
      payRunAdapter.upsertMany(state.payRuns, action.payload)
    },
    replacePayRuns: (state, action: PayloadAction<PayRunState[]>) => {
      payRunAdapter.setAll(state.payRuns, action.payload)
    },
    removePayRun: (state, action: PayloadAction<string>) => {
      payRunAdapter.removeOne(state.payRuns, action.payload)
    },
    setPayRunSummary: (state, action: PayloadAction<PayRunSummaryState>) => {
      payRunSummaryAdapter.upsertOne(state.payRunSummaries, action.payload)
    },
    replacePayRunSummaries: (state, action: PayloadAction<PayRunSummaryState[]>) => {
      payRunSummaryAdapter.setAll(state.payRunSummaries, action.payload)
    },
    setPayRunSummaryLoading: (state, action: PayloadAction<boolean>) => {
      state.payRunSummariesLoading = action.payload
    },

    // payRunsView
    refetchPayRunsView: state => {
      state.payRunsViewRefetch += 1
    },
    setPayRunsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.payRunsViewLoading = action.payload
    },
    setPayRunsView: (state, action: PayloadAction<PayRunViewState>) => {
      payRunViewAdapter.upsertOne(state.payRunsView, action.payload)
    },

    setPayRecordLoading: (state, action: PayloadAction<{ payRunId: string; loading: boolean }>) => {
      const { payRunId, loading } = action.payload
      state.payRecords[payRunId] = state.payRecords[payRunId] || payRecordInitialState
      state.payRecordsLoading[payRunId] = loading
    },
    setPayRecord: (state, action: PayloadAction<{ payRunId: string; data: PayRecordSgState }>) => {
      const { payRunId, data } = action.payload
      payRecordAdapter.upsertOne(state.payRecords[payRunId]!, data)
    },
    setPayRecords: (state, action: PayloadAction<{ payRunId: string; data: PayRecordSgState[] }>) => {
      const { payRunId, data } = action.payload
      payRecordAdapter.upsertMany(state.payRecords[payRunId]!, data)
    },
    replacePayRecords: (state, action: PayloadAction<{ payRunId: string; data: PayRecordSgState[] }>) => {
      const { payRunId, data } = action.payload
      payRecordAdapter.setAll(state.payRecords[payRunId], data)
    },
    removePayRecord: (state, action: PayloadAction<{ payRunId: string; id: string }>) => {
      const { payRunId, id } = action.payload
      payRecordAdapter.removeOne(state.payRecords[payRunId]!, id)
    },
    replacePayRecordPayRuns: (state, action: PayloadAction<{ employeeId: string; data: IdValue[] }>) => {
      const { employeeId, data } = action.payload
      payRecordPayRunAdapter.setAll(state.payRecordPayRuns[employeeId], data)
    },
    setPayRecordPayRunsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.payRecordPayRuns[employeeId] = state.payRecordPayRuns[employeeId] || payRecordPayRunInitialState
      state.payRecordPayRunsLoading[employeeId] = loading
    },
    replacePayRecordEmployees: (state, action: PayloadAction<{ payRunId: string; data: EmSelectState[] }>) => {
      const { payRunId, data } = action.payload
      payRecordEmployeeAdapter.setAll(state.payRecordEmployees[payRunId], data)
    },
    setPayRecordEmployeesLoading: (state, action: PayloadAction<{ payRunId: string; loading: boolean }>) => {
      const { payRunId, loading } = action.payload
      state.payRecordEmployees[payRunId] = state.payRecordEmployees[payRunId] || payRecordEmployeeInitialState
      state.payRecordEmployeesLoading[payRunId] = loading
    },
    setPayWarningLoading: (state, action: PayloadAction<{ payRunId: string; loading: boolean }>) => {
      const { payRunId, loading } = action.payload
      state.payWarnings[payRunId] = state.payWarnings[payRunId] || payWarningInitialState
      state.payWarningLoading[payRunId] = loading
    },
    replacePayWarnings: (state, action: PayloadAction<{ payRunId: string; data: PayWarningState[] }>) => {
      const { payRunId, data } = action.payload
      payWarningAdapter.setAll(state.payWarnings[payRunId], data)
    },
    setPayWarning: (state, action: PayloadAction<{ payRunId: string; data: PayWarningState }>) => {
      const { payRunId, data } = action.payload
      payWarningAdapter.upsertOne(state.payWarnings[payRunId], data)
    },
    setPaySnapshot: (state, action: PayloadAction<{ payRunId: string; data: PaySnapshotState }>) => {
      const { payRunId, data } = action.payload
      state.paySnapshot[payRunId] = data
    },
    setPaySnapshotLoading: (state, action: PayloadAction<{ payRunId: string; loading: boolean }>) => {
      const { payRunId, loading } = action.payload
      state.paySnapshot[payRunId] = state.paySnapshot[payRunId] || paySnapshotInitialState
      state.paySnapshotLoading[payRunId] = loading
    },
    setPayTranLoading: (state, action: PayloadAction<{ recordId: string; loading: boolean }>) => {
      const { recordId, loading } = action.payload
      state.payTrans[recordId] = state.payTrans[recordId] || payTranInitialState
      state.payTransLoading[recordId] = loading
    },
    replacePayTrans: (state, action: PayloadAction<{ recordId: string; data: PayTranSgState[] }>) => {
      const { recordId, data } = action.payload
      payTranAdapter.setAll(state.payTrans[recordId]!, data)
    },
    removePayTran: (state, action: PayloadAction<{ recordId: string; id: string }>) => {
      const { recordId, id } = action.payload
      payTranAdapter.removeOne(state.payTrans[recordId]!, id)
    },
    setPayTranDtlLoading: (state, action: PayloadAction<{ tranId: string; loading: boolean }>) => {
      const { tranId, loading } = action.payload
      state.payTranDtls[tranId] = state.payTranDtls[tranId] || payTranDtlInitialState
      state.payTranDtlsLoading[tranId] = loading
    },
    replacePayTranDtls: (state, action: PayloadAction<{ tranId: string; data: PayTranDtlSgState[] }>) => {
      const { tranId, data } = action.payload
      payTranDtlAdapter.setAll(state.payTranDtls[tranId]!, data)
    },
    removePayTranDtl: (state, action: PayloadAction<{ tranId: string; id: string }>) => {
      payTranDtlAdapter.removeOne(state.payTranDtls[action.payload.tranId]!, action.payload.id)
    },
    setPayrollLoading: (state, action: PayloadAction<boolean>) => {
      state.payrollLoading = action.payload
    },
    setPayTranEntryLoading: (state, action: PayloadAction<{ batchId: string; loading: boolean }>) => {
      const { batchId, loading } = action.payload
      state.payTranEntries[batchId] = state.payTranEntries[batchId] || payTranEntryInitialState
      state.payTranEntriesLoading[batchId] = loading
    },
    replacePayTranEntry: (state, action: PayloadAction<{ batchId: string; data: PayTranEntryState[] }>) => {
      const { batchId, data } = action.payload
      payTranEntryAdapter.setAll(state.payTranEntries[batchId]!, data)
    },
    removePayTranEntryByBatch: (state, action: PayloadAction<string>) => {
      const batchId = action.payload
      payTranEntryAdapter.removeAll(state.payTranEntries[batchId]!)
    },
    setPayTranImportLoading: (state, action: PayloadAction<{ emBatchId: string; loading: boolean }>) => {
      const { emBatchId, loading } = action.payload
      state.payTranImports[emBatchId] = state.payTranImports[emBatchId] || payTranImportInitialState
      state.payTranImportsLoading[emBatchId] = loading
    },
    replacePayTranImport: (state, action: PayloadAction<{ emBatchId: string; data: PayTranImportState[] }>) => {
      const { emBatchId, data } = action.payload
      state.payTranImports[emBatchId] = state.payTranImports[emBatchId] || payTranImportInitialState
      payTranImportAdapter.setAll(state.payTranImports[emBatchId]!, data)
    },
    removePayTranImportByBatch: (state, action: PayloadAction<string>) => {
      const batchId = action.payload
      payTranImportAdapter.removeAll(state.payTranImports[batchId]!)
    },
    setPayAccountLoading: (state, action: PayloadAction<{ recordId: string; loading: boolean }>) => {
      const { recordId, loading } = action.payload
      state.payAccounts[recordId] = state.payAccounts[recordId] || payAccountInitialState
      state.payAccountsLoading[recordId] = loading
    },
    replacePayAccount: (state, action: PayloadAction<{ recordId: string; data: PayAccountState[] }>) => {
      const { recordId, data } = action.payload
      payAccountAdapter.setAll(state.payAccounts[recordId]!, data)
    },
    setPayItemMappingLoading: (state, action: PayloadAction<boolean>) => {
      state.payItemMappingsLoading = action.payload
    },
    setPayItemMapping: (state, action: PayloadAction<PayItemMappingState>) => {
      payItemMappingAdapter.upsertOne(state.payItemMappings, action.payload)
    },
    setEmPayroll: (state, action: PayloadAction<EmPayrollState>) => {
      emPayrollAdapter.upsertOne(state.emPayroll, action.payload)
    },
    setEmPayrollLoading: (state, action: PayloadAction<boolean>) => {
      state.emPayrollLoading = action.payload
    },
    setPayslip: (state, action: PayloadAction<PayslipState>) => {
      payslipAdapter.upsertOne(state.payslip, action.payload)
    },
    setPayslipLoading: (state, action: PayloadAction<boolean>) => {
      state.payslipLoading = action.payload
    },
    setPayslipGenerating: (state, action: PayloadAction<boolean>) => {
      state.payslipGenerating = action.payload
    },
    setIsPayslipOutdated: (state, action: PayloadAction<{ employeeId: string; data: boolean }>) => {
      const { employeeId, data } = action.payload
      state.isPayslipOutdated[employeeId] = data
    },
    setIsAnyPayslipsOutdated: (state, action: PayloadAction<{ payRunId: string; data: boolean }>) => {
      const { payRunId, data } = action.payload
      state.isAnyPayslipsOutdated[payRunId] = data
    },
    setPayRunPayslip: (state, action: PayloadAction<{ payRunId: string; data: PayRunPayslipState }>) => {
      const { payRunId, data } = action.payload
      state.payRunPayslip[payRunId] = data
    },
    setPayRunPayslipLoading: (state, action: PayloadAction<{ payRunId: string; loading: boolean }>) => {
      const { payRunId, loading } = action.payload
      state.payRunPayslip[payRunId] = state.payRunPayslip[payRunId] || payRunPayslipInitialState
      state.payRunPayslipLoading[payRunId] = loading
    },

    // payCfConfigs
    replacePayCfConfigs: (state, action: PayloadAction<PayCfConfigState[]>) => {
      payCfConfigsAdapter.setAll(state.payCfConfigs, action.payload)
    },
    setPayCfConfig: (state, action: PayloadAction<PayCfConfigState>) => {
      payCfConfigsAdapter.upsertOne(state.payCfConfigs, action.payload)
    },
    setPayCfConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.payCfConfigsLoading = action.payload
    },

    // cpfSubmissions
    refetchCpfSubmissionsView: state => {
      state.cpfSubmissionsViewRefetch += 1
    },
    setCpfSubmissionsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.cpfSubmissionsViewLoading = action.payload
    },
    setCpfSubmissionsView: (state, action: PayloadAction<CpfSubmissionViewState>) => {
      cpfSubmissionsViewAdapter.upsertOne(state.cpfSubmissionsView, action.payload)
    },
    setCpfApexState: (state, action: PayloadAction<string>) => {
      state.cpfApexState = action.payload
      state.cpfApexStateTimestamp = new Date().getTime()
    },
    setCpfSubmissionForm: (state, action: PayloadAction<CpfSubmissionFormState>) => {
      state.cpfSubmissionForm = action.payload
    },
    setCpfSubmission: (state, action: PayloadAction<CpfSubmissionState>) => {
      cpfSubmissionsAdapter.upsertOne(state.cpfSubmissions, action.payload)
    },
    setCpfSubmissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.cpfSubmissionsLoading = action.payload
    },

    // Pay run xero
    setPayRunXero: (state, action: PayloadAction<PayRunXeroState>) => {
      payRunXeroAdapter.upsertOne(state.payRunXero, action.payload)
    },
    setPayRunXeroLoading: (state, action: PayloadAction<boolean>) => {
      state.payRunXeroLoading = action.payload
    },
    refetchPayRunXero: state => {
      state.payRunXeroRefecth += 1
    },

    replacePayImport: (state, action: PayloadAction<PayImportState>) => {
      state.payImport = action.payload
    },
    replacePayImportValidate: (state, action: PayloadAction<PayImportValidateState>) => {
      state.payImportValidate = action.payload
    },
    replaceLeaveImport: (state, action: PayloadAction<LeaveImportState>) => {
      state.leaveImport = action.payload
    },
    replaceLeaveImportValidate: (state, action: PayloadAction<LeaveImportValidateState>) => {
      state.leaveImportValidate = action.payload
    },
    replaceEncashImport: (state, action: PayloadAction<EncashImportState>) => {
      state.encashImport = action.payload
    },
    replaceEncashImportValidate: (state, action: PayloadAction<EncashImportValidateState>) => {
      state.encashImportValidate = action.payload
    },
    replaceClaimImport: (state, action: PayloadAction<ClaimImportState>) => {
      state.claimImport = action.payload
    },
    replaceClaimImportValidate: (state, action: PayloadAction<ClaimImportValidateState>) => {
      state.claimImportValidate = action.payload
    },
    replaceDailyImport: (state, action: PayloadAction<DailyImportState>) => {
      state.dailyImport = action.payload
    },
    replaceDailyImportValidate: (state, action: PayloadAction<DailyImportValidateState>) => {
      state.dailyImportValidate = action.payload
    },
    refetchPaySummary: state => {
      state.payRunSummariesRefetch += 1
    },
    refetchPayRecords: state => {
      state.payRecordsRefetch += 1
    },
    refetchPayslips: state => {
      state.payslipEmployeeRefetch += 1
    },

    // Xero integration
    setXeroState: (state, action: PayloadAction<string>) => {
      state.xeroState = action.payload
      state.xeroStateTimestamp = new Date().getTime()
    },
    setXeroRedirectUrl: (state, action: PayloadAction<string>) => {
      state.xeroRedirectUrl = action.payload
    },
    replaceXeroConnections: (state, action: PayloadAction<XeroConnectionState[]>) => {
      xeroConnectionsAdapter.setAll(state.xeroConnections, action.payload)
    },
    removeXeroConnection: (state, action: PayloadAction<string>) => {
      xeroConnectionsAdapter.removeOne(state.xeroConnections, action.payload)
    },
    setXeroConnectionLoading: (state, action: PayloadAction<boolean>) => {
      state.xeroConnectionsLoading = action.payload
    },
    replaceXeroTenants: (state, action: PayloadAction<XeroTenantState[]>) => {
      xeroTenantsAdapter.setAll(state.xeroTenants, action.payload)
    },
    setXeroTenantsLoading: (state, action: PayloadAction<boolean>) => {
      state.xeroTenantsLoading = action.payload
    },
    replaceXeroTenantMaps: (state, action: PayloadAction<XeroTenantMapState[]>) => {
      xeroTenantMapsAdapter.setAll(state.xeroTenantMaps, action.payload)
    },
    setXeroTenantMapsLoading: (state, action: PayloadAction<boolean>) => {
      state.xeroTenantMapsLoading = action.payload
    },
    setXeroConfig: (state, action: PayloadAction<XeroConfigState>) => {
      xeroConfigAdapter.upsertOne(state.xeroConfig, action.payload)
    },
    setXeroConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.xeroConfigLoading = action.payload
    },
    replaceXeroLineItemMaps: (state, action: PayloadAction<{ companyId: string; data: XeroLineItemMapState[] }>) => {
      const { companyId, data } = action.payload
      xeroLineItemMapsAdapter.setAll(state.xeroLineItemMaps[companyId]!, data)
    },
    setXeroLineItemMapsLoading: (state, action: PayloadAction<{ companyId: string; loading: boolean }>) => {
      const { companyId, loading } = action.payload
      state.xeroLineItemMaps[companyId] = state.xeroLineItemMaps[companyId] || xeroLineItemMapInitialState
      state.xeroLineItemMapsLoading[companyId] = loading
    },
    replaceXeroAccounts: (state, action: PayloadAction<{ tenantId: string; data: XeroAccountState[] }>) => {
      const { tenantId, data } = action.payload
      xeroAccountsAdapter.setAll(state.xeroAccounts[tenantId]!, data)
    },
    setXeroAccountsLoading: (state, action: PayloadAction<{ tenantId: string; loading: boolean }>) => {
      const { tenantId, loading } = action.payload
      state.xeroAccounts[tenantId] = state.xeroAccounts[tenantId] || xeroAccountInitialState
      state.xeroAccountsLoading[tenantId] = loading
    },
    replaceXeroContacts: (state, action: PayloadAction<{ tenantId: string; data: XeroContactState[] }>) => {
      const { tenantId, data } = action.payload
      xeroContactsAdapter.setAll(state.xeroContacts[tenantId]!, data)
    },
    setXeroContactsLoading: (state, action: PayloadAction<{ tenantId: string; loading: boolean }>) => {
      const { tenantId, loading } = action.payload
      state.xeroContacts[tenantId] = state.xeroContacts[tenantId] || xeroContactInitialState
      state.xeroContactsLoading[tenantId] = loading
    }
  }
})

export const {
  setPayRecurring,
  setPayRecurringLoading,
  replacePayRecurrings,
  removePayRecurring,
  setPayRecurringOverride,
  setPayRecurringOverrideLoading,
  removePayRecurringOverride,
  setPayRunExpanded,
  setPayRun,
  setPayRunLoading,
  setPayRuns,
  replacePayRuns,
  removePayRun,
  setPayRunSummary,
  replacePayRunSummaries,
  setPayRunSummaryLoading,

  // payRunsView
  refetchPayRunsView,
  setPayRunsViewLoading,
  setPayRunsView,

  setPayRecordLoading,
  setPayRecord,
  setPayRecords,
  replacePayRecords,
  removePayRecord,
  replacePayRecordPayRuns,
  setPayRecordPayRunsLoading,
  replacePayRecordEmployees,
  setPayRecordEmployeesLoading,
  setPayTranLoading,
  replacePayTrans,
  removePayTran,
  setPayTranDtlLoading,
  replacePayTranDtls,
  removePayTranDtl,
  setPayrollLoading,
  replacePayTranEntry,
  setPayTranEntryLoading,
  removePayTranEntryByBatch,
  replacePayTranImport,
  setPayTranImportLoading,
  removePayTranImportByBatch,
  replacePayAccount,
  setPayAccountLoading,
  setPayItemMapping,
  setPayItemMappingLoading,
  replacePayWarnings,
  setPayWarning,
  setPayWarningLoading,
  setPaySnapshot,
  setPaySnapshotLoading,
  setEmPayroll,
  setEmPayrollLoading,
  setPayslip,
  setPayslipLoading,
  setPayslipGenerating,
  setIsPayslipOutdated,
  setIsAnyPayslipsOutdated,
  replacePayImport,
  replacePayImportValidate,
  replaceLeaveImport,
  replaceLeaveImportValidate,
  replaceEncashImport,
  replaceEncashImportValidate,
  replaceClaimImport,
  replaceClaimImportValidate,
  replaceDailyImport,
  replaceDailyImportValidate,
  refetchPaySummary,
  refetchPayRecords,
  refetchPayslips,

  setPayRunPayslip,
  setPayRunPayslipLoading,

  // PayCfConfigs
  replacePayCfConfigs,
  setPayCfConfig,
  setPayCfConfigsLoading,

  // cpfSubmissionsView
  refetchCpfSubmissionsView,
  setCpfSubmissionsViewLoading,
  setCpfSubmissionsView,
  setCpfApexState,
  setCpfSubmissionForm,
  setCpfSubmission,
  setCpfSubmissionsLoading,

  setPayRunXero,
  setPayRunXeroLoading,
  refetchPayRunXero,

  //Xero integration
  setXeroState,
  setXeroRedirectUrl,
  replaceXeroConnections,
  removeXeroConnection,
  setXeroConnectionLoading,
  replaceXeroTenants,
  setXeroTenantsLoading,
  replaceXeroTenantMaps,
  setXeroTenantMapsLoading,
  setXeroConfig,
  setXeroConfigLoading,
  replaceXeroLineItemMaps,
  replaceXeroAccounts,
  setXeroAccountsLoading,
  replaceXeroContacts,
  setXeroContactsLoading,
  setXeroLineItemMapsLoading
} = payrollSlice.actions

export const { selectById: selectPayRunById } = payRunAdapter.getSelectors((state: StoreState) => state.payroll.payRuns)

export const { selectById: selectPayRecurringOverrideById } = payRecurringOverrideAdapter.getSelectors(
  (state: StoreState) => state.payroll.payRecurringOverrides
)

export const { selectById: selectPayItemMappingById } = payItemMappingAdapter.getSelectors(
  (state: StoreState) => state.payroll.payItemMappings
)

export const { selectById: selectPaySummaryById } = payRunSummaryAdapter.getSelectors(
  (state: StoreState) => state.payroll.payRunSummaries
)

export const { selectById: selectEmPayrollById } = emPayrollAdapter.getSelectors(
  (state: StoreState) => state.payroll.emPayroll
)

export const payrollReducers = { payroll: persistReducer<PayrollRootState>(payrollPersistConfig, payrollSlice.reducer) }
