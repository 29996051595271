import { FC } from 'react'
import { Card, Tabs } from '~/core-components'
import { CpfSubmissionForm } from './CpfSubmissionForm'
import './CpfSubmissionCard.less'

interface CpfSubmissionCardProps {}

export const CpfSubmissionCard: FC<CpfSubmissionCardProps> = () => {
  return (
    <Card className="cpf-submission-card">
      <Tabs
        items={[
          {
            key: 'submit',
            label: 'Direct submision',
            children: <CpfSubmissionForm type="submit" />
          },
          {
            key: 'download',
            label: 'Download',
            children: <CpfSubmissionForm type="download" />
          }
        ]}
      />
    </Card>
  )
}
